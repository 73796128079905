export default async (modul_id,actualRoom,settings) =>
{
    console.log('%c 📺Stream Module Loaded! ', 'background: #222; color: #bada55');
    Echo.private(`room.${actualRoom}.module.Stream.settings`)
   .listen('ModuleSettingsUpdate', async (event) => {
       console.log("ModuleSettingsUpdate", event);
       if(event.settings.enabled === "0")
       {
          document.querySelector(`[data-module-relation-id='${modul_id}'] [data-module-content]`).innerHTML = "";
       }
       else {
           var compiled_html = "";
           if(event.settings.headline !='') compiled_html += "<h2>"+event.settings.headline+"</h2>";
           if(event.settings.description !='') compiled_html += "<p>"+event.settings.description;
           compiled_html += '<div class="iframe_wrapper" style="">';
           compiled_html += '   <iframe width="1920" height="1080" src="'+event.settings.stream_url+'" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
           compiled_html += ' </div>';
           document.querySelector(`[data-module-relation-id='${modul_id}'] [data-module-content]`).innerHTML = compiled_html;
       }
   });
};
