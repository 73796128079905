<template>
<div class="composer">
  <textarea v-model="message" @keydown.enter="send" placeholder=""></textarea>
  <button v-on:click="send" class="btn btn-primary"><i class="far fa-paper-plane"></i></button>
</div>

</template>

<script>
    export default {

		data() {
			return {
        message: '',
				selectedContact: null,
				messages: [],
				contacts: [],
				typingUser: false,
				typingTimer: false,
				chatVisible: false,
				conversationVisible: false,
				unreadCount: 0,
				callingAudio:null,
				callingContact: null,
				isCalling: false,
				isTalking: false
            };
        },
    mounted() {

			Echo.join(`room.${this.module_room_name}.module.Comments.update`)
      Echo.channel(`room.${this.module_room_name}.module.Comments.newComment.update`)
                .listen('.Modules\\Comments\\Events\\NewComment', (e) => {

                });
},
    methods: {
        send(e) {
  				e.preventDefault();

  				if (this.message == '') {
  					return;
  				}

          axios.post(`/room/${this.module_room_name}/module/Comments/api?action=conversation`, {
              room_id: `${this.room_id}`,
              text: this.message
          }).then((response) => {
              this.$emit('new', response.data);
          })


  				this.message = '';
  			}



        }
    }

</script>
<style lang="scss" scoped>
.chat-app
	{
	display: flex;
	align-items:center;
	position: absolute;
	right: 0;
	margin: 0;
	padding: 0;
	top: 10%;
	height:80%;
	z-index:2;
	border-radius: 1.0em 0 0 1.0em;
	background-color: rgba(255,255,255,0.0);;
	transition: opacity background-color .5s;

	&.expanded
	{
		opacity: 1;
		transition: opacity background-color .5s;
        background-color: rgba(255,255,255,1.0);
		.toggleChat
		{
			   margin-left: -25px;
			   margin-right: 0px;
		}
	}
	.buttonContainer
	{
		/*position:relative;*/
	}
	.toggleChat
	{
	    border-radius: 50%;
	    border: 0;
	    background-color: var(--theme_color);
	    width: 50px;
	    height: 50px;
	    z-index: 2;
		position:relative;
		margin-right: 25px;
		.badge
		{
			position:absolute;
			background-color: var(--white);
			width:24px;
			height:24px;
			color: var(--theme_color);
			right:0;
			border-radius: 50%;
			padding:0;
			text-align:center;
			line-height:22px;
			font-size:10px;
			margin-top: -5px;
			margin-right: -5px;
			&.message-shake
			{
				animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
				transform: translate3d(0, 0, 0);
			}
		}
		&:focus {
		    background-color: var(--theme_color);
		    border: 0;
		    box-shadow: none;
		}
	}
}
@keyframes shake {
  10%, 90% {
	transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
	transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
	transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
	transform: translate3d(4px, 0, 0);
  }
}
.composer
{
	display: flex;
	align-items: center;
	position: relative;
	textarea {
		width: 100%;
		margin: 0;
		border-radius: 0;
		resize: none;
		border: 1px solid lightgray;
		padding: 6px 10% 6px 20px;
		padding-top: 12px;
		height: 50px;
	}
	.btn
	{
		background-color: var(--theme_color);
		border-radius: 0;
		border: 0;
		position: absolute;
		margin-right: 0;
		right: 0;
    width: 50px;
    height: 50px;
	}
}

</style>
