require('../../../../../../resources/js/bootstrap');
import { changeRoom } from '../../../../../../resources/js/frontend';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/photoswipe.css';
let navi;
export default async (modul_id, actualRoom, settings) => {

    const data = await (await fetch(`/gallery/${actualRoom}/module/booking/api?action=navigation`)).json();
    const axios = require('axios').default;

    await buildMapNavigation(data.navi, modul_id);

    let readyButton = document.getElementById('readyButton');
    readyButton.addEventListener('click', (event => {
        event.preventDefault();
        axios.post(`/gallery/${actualRoom}/module/booking/api?action=status`, {
            status: "1",
        })
            .then(function (response) {
                //console.log(response);
                //disableButtons();
                let selectionText = document.getElementById('selection-text');
                selectionText.innerText = "Vielen Dank für Deine Auswahl.";
                document.body.scrollTop = 0; 
                document.documentElement.scrollTop = 0;
            })
            .catch(function (error) {
                console.log(error);
            });

    }));
    if (settings.status == "1") {
        //disableButtons();
    };

    console.log('%c 📍 bookingModule Loaded! ', 'background: #222; color: #bada55');

}
function limitalert () {
    alert("Du kannst keine weiteren Bilder auswählen.");
}

function countUp () {
    var obj = document.getElementById("imgist");
    var current = obj.innerHTML;
    current++;
    obj.innerHTML = current;
}

function countDown () {
    var obj = document.getElementById("imgist");
    var current = obj.innerHTML;
    current--;
    obj.innerHTML = current;
}

function disableButtons () {
    let allButtons = document.getElementsByClassName("btn");

    for (let i = 0; i < allButtons.length; i++) {
        const aBtn = allButtons[i];
        aBtn.classList.add("disabled");
    }
}


function buildMapNavigation (roomList, modul_id) {
    navi = document.getElementById('booking_' + modul_id + '_items');
    const options = {
        gallery: '#booking_' + modul_id + '_items',
        children: 'a',
        pswpModule: () => import('photoswipe'),
        bgOpacity: 0.9,
        returnFocus: true,
    };
    const lightbox = new PhotoSwipeLightbox(options);
    lightbox.on('uiRegister', function () {
        lightbox.pswp.ui.registerElement({
            name: 'like-button',
            ariaLabel: 'like',
            order: 9,
            isButton: true,
            html: '<button id="innerLikeBtn" class="btn btn-like btn-secondary"><i class="fas fa-thumbs-up"></i></button>',
            onClick: (event, el) => {
                let slide = document.querySelector('[data-slide_id="' + lightbox.pswp.currSlide.index + '"]');
                let innerLikeBtn = document.getElementById('innerLikeBtn');
                let innerlink = document.querySelector('.popup-btn[data-slide_id="' + lightbox.pswp.currSlide.index + '"]');
                let normalLikeBtn = document.querySelector('.btn-like[data-slide_id="' + lightbox.pswp.currSlide.index + '"]');
                axios.post(`/gallery/${actualRoom}/module/booking/api?action=like`, {
                    image_id: slide.dataset.image_id,
                })
                    .then(function (response) {

                        if (response.data == true) {
                            innerLikeBtn.classList.add("btn-success");
                            innerLikeBtn.classList.remove("btn-secondary");
                            normalLikeBtn.classList.add("btn-success");
                            normalLikeBtn.classList.remove("btn-secondary");
                            innerlink.dataset.like = "true";
                            countUp();

                        } else if (response.data == false) {
                            innerLikeBtn.classList.add("btn-secondary");
                            innerLikeBtn.classList.remove("btn-success");
                            normalLikeBtn.classList.add("btn-secondary");
                            normalLikeBtn.classList.remove("btn-success");
                            innerlink.dataset.like = "false";
                            countDown();
                        } else if (response.data == "limit") {
                            limitalert();
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        });
    });
    lightbox.on('change', (event => {
        // triggers when slide is switched, and at initialization
        let slide = document.querySelector('[data-slide_id="' + lightbox.pswp.currSlide.index + '"]');
        let innerLikeBtn = document.getElementById('innerLikeBtn');
        if (slide.dataset.like == "true") {
            innerLikeBtn.classList.add("btn-success");
            innerLikeBtn.classList.remove("btn-secondary");
        } else if (slide.dataset.like == "false") {
            innerLikeBtn.classList.add("btn-secondary");
            innerLikeBtn.classList.remove("btn-success");
        }
    }));

    for (let i = 0; i < roomList.length; i++) {
        const room = roomList[i]; //class, target name, type, value, object
        const naviTemplate = document.getElementById('booking_' + modul_id + '_element');
        let clone = document.importNode(naviTemplate.content, true);
        let link = clone.querySelector(".popup-btn");
        let image = clone.querySelector("img");
        let button = clone.querySelector("button");
        link.dataset.slide_id = i;
        button.dataset.slide_id = i;
        link.dataset.image = room.image;
        link.dataset.class = room.class;
        link.dataset.popup_image = room.image;
        link.dataset.cropped = true;
        link.dataset.gallery_item_id = modul_id + "_" + i;

        if (room.class != null) {
            link.classList.add(room.class);
        }
        link.style = link.style += (room.css ?? "");
        switch (room.type) {
            case "image":
                axios.get(`/gallery/${actualRoom}/module/booking/public?action=metadata&image_id=${room.image_id}`)
                .then(response => {
                    //console.log(response.data);
                    link.dataset.pswpWidth = response.data[0];
                    link.dataset.pswpHeight = response.data[1];
  });
                link.title = room.name ?? "";
                link.dataset.image_id = room.image_id;
                button.dataset.image_id = room.image_id;
                image.src = `/gallery/${actualRoom}/module/booking/public?action=thumbnail&image_id=${room.image_id}`;
                link.href = `/gallery/${actualRoom}/module/booking/public?action=image&image_id=${room.image_id}`;
                var img = new Image();
                img.onload = function () {
                    link.dataset.pswpWidth = this.width;
                    link.dataset.pswpHeight = this.height;
                };
                img.loading = "lazy";
                img.src = `/gallery/${actualRoom}/module/booking/public?action=image&image_id=${room.image_id}`;
                //  button.setAttribute("onClick", `likeButton("${room.image}");`);
                if (room.like == true) {
                    button.classList.add("btn-success");
                    link.dataset.like = "true";
                } else {
                    button.classList.add("btn-secondary");
                    link.dataset.like = "false";
                }

        }
        /* link.addEventListener('click', (event) => {
             console.log(event);
             switch (event.path[1].dataset.type) {
                 case "image":
                     event.preventDefault();
                     if (event.path[1].dataset.popup_image == ("null" || "")) return;
                     var popup_image_modal = navi.querySelector(".popup_image_container .modal");
                     //console.log(event.target);
                     popup_image_modal.style.display = "block";
                     popup_image_modal.querySelector("img").src = `/gallery/${actualRoom}/module/booking/public?action=image&image_id=${room.image_id}`;
                     if (event.path[1].alt != "null" && event.path[1].alt != "") {
                         popup_image_modal.querySelector(".caption").style.display = "none";
                         popup_image_modal.querySelector(".caption").innerHTML = event.path[1].alt;
 
                     }
                     else {
                         popup_image_modal.querySelector(".caption").style.display = "none";
                         popup_image_modal.querySelector(".caption").innerHTML = "";
                     }
                     // Get the <span> element that closes the modal
                     var span = popup_image_modal.querySelector(".close");
                     // When the user clicks on <span> (x), close the modal
                     span.onclick = function () {
                         popup_image_modal.style.display = "none";
                     }
                     popup_image_modal.onclick = function (event) {
                         popup_image_modal.style.display = "none";
                     }
                     break;
             }
         });
         */
        button.addEventListener('click', (event => {
            event.preventDefault();
            axios.post(`/gallery/${actualRoom}/module/booking/api?action=like`, {
                image_id: room.image_id,
            })
                .then(function (response) {
                    if (response.data == true) {
                        button.classList.add("btn-success");
                        button.classList.remove("btn-secondary");
                        link.dataset.like = "true";
                        countUp();

                    } else if (response.data == false) {
                        button.classList.add("btn-secondary");
                        button.classList.remove("btn-success");
                        link.dataset.like = "false";
                        countDown();
                    } else if (response.data == "limit") {
                        limitalert();
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

        }));

        navi.appendChild(clone);

    }
    lightbox.init();

}



