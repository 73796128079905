<template>
	<div :class="`contacts-list ${showCallFrame ? ' calling' : ''}`">
		<div class="calling-container" v-if="showCallFrame">
			<h2>Incoming Call</h2>
			<div class="row">
				<div class="img" :style="{'background-image':`url(/image/user/${callingContact.id})`}" />
			</div>
			<div class="row contact">
				<p class="name">{{ callingContact.forename }} {{ callingContact.surname }}</p>
				<p class="email">{{ callingContact.email }}</p>
			</div>
			<div class="row justify-content-center">
			<button v-on:click="accept" class="btn-phone accept"><div class="phone-ph-circle"></div>
			<div class="phone-ph-circle-fill"></div>
			<div class="phone-ph-img-circle"><i class="fas fa-phone"></i></div></button>
		</div>
		<div class="row justify-content-center">
			<button v-on:click="deny" class="btn-phone deny">
			<div class="phone-ph-img-circle"><i class="fas fa-phone"></i></div></button>
			</div>
		</div>
		<h2>Active Chats</h2>
		<ul class="container contacted">
			<li v-for="contact in contactedContacts" :key="contact.id" @click="selectContact(contact)" :class="`row ${contact == selected ? 'selected' : ''}`">
				<div class="col avatar">
					<div class="img" :style="{'background-image':`url(/image/user/${contact.id})`}" />
					<div :class="`status ${contact.status}`"></div>
				</div>
				<div class="col contact">
					<p class="name">{{ contact.forename }} {{ contact.surname }}</p>
					<p class="email">{{ contact.email }}</p>
				</div>
				<div class="col">
					<span class="unread" v-if="contact.unread">{{ contact.unread }}</span>
				</div>
			</li>
		</ul>
		<h2>Available User</h2>
		<ul class="container all">
			<li v-for="contact in availableContacts" :key="contact.id" @click="selectContact(contact)" :class="{ 'selected': contact == selected }">
				<div class="col avatar">
					<div class="img" :style="{'background-image':`url(/image/user/${contact.id})`}" />
					<div :class="`status ${contact.status}`"></div>
				</div>
				<div class="col contact">
					<p class="name">{{ contact.forename }} {{ contact.surname }}</p>
					<p class="email">{{ contact.email }}</p>
				</div>
				<div class="col">
					<span class="unread" v-if="contact.unread">{{ contact.unread }}</span>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
	export default {
		props: {
			contacts: {
				type: Array,
				default: []
			},
			chatVisible: {
				type: Boolean,
				default:false
			},
			callingContact: {
			   type: Object,
			   default: null
		   },
			isCalling: {
				type: Boolean
			},
			isTalking: {
				type: Boolean
			}
		},
		data() {
			return {
				selected: null,
				showCallFrame: false,
				roomUrl: ""
			};
		},
		mounted() {
	//		console.log("mounted");
	//		console.log(this.callingContact);
		},
		methods: {
			selectContact(contact) {

				if(this.isTalking)
				{
					var r = confirm("The call will be ended, if you select another conversation!");
					if (r == true) {
						this.$emit('cancelCall');
					  	this.selected = contact;
	  					this.$emit('selected', contact);
					}

				}
				else
				{
					this.selected = contact;
					this.$emit('selected', contact);
				}

			},
			clearSelected()
			{
				this.selected = null;
			},
			receiveCalling(roomUrl)
			{
				this.roomUrl = roomUrl;
				this.showCallFrame = true;
			},
			cancelCalling()
			{
				this.showCallFrame = false;
				console.log("cancel calling contact linst");
			},
			accept()
			{
				console.log("accept");
			//	this.joinRoom(this.roomUrl);
				this.showCallFrame = false;
				this.selectContact(this.callingContact);

				this.$nextTick(() => {
					this.$emit('acceptCall',this.roomUrl);
				});

			},
			deny()
			{
				console.log("deny call");
				this.showCallFrame = false;
				this.$emit('denyCall');
			},
		},
		computed: {
			contactedContacts() {
				return _.sortBy( _.pickBy(this.contacts, function(contact) {
					return contact.contacted;
				}), [(contact) => {
					if (contact == this.selected) {
					return Infinity;
					}

					return contact.unread;
				}]).reverse();
			},
			/*contactedContacts: function() {

			},*/
			availableContacts: function() {
				return _.sortBy( _.pickBy(this.contacts, function(contact) {
					return !contact.contacted;
				}), [(contact) => {
					if (contact == this.selected) {
					return Infinity;
					}

					return contact.unread;
				}]).reverse();
			}
		}
	}
</script>

<style lang="scss" scoped>
.contacts-list {
  padding: 0.75rem 0.75rem 0.75rem 0;
  max-height: 100%;
  height: 100%;
  width: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  &.calling
  {
	  overflow-y: hidden;
  }
  .calling-container
  {
		background: white;
		width: 100%;
		height: calc(100% + 1.5rem);
		margin-top: -0.75rem;
		align-content: center;
		justify-content: center;
		display: grid;
		.contact
		{
			text-align:center;
			.name
			{
				font-size: 2em;
				margin:0;
			}
			.email
			{
				color: var(--font-color-secondary);
				margin:0;

			}

		}
		.img {
          width: 100px;
          height: 100px;
          background-size: cover;
          border-radius: 50%;
          margin: 0 auto;
        }
  }
  h2 {
    font-size: 15px;
    color: var(--font-color);
    position: relative;
    margin-bottom: 20px;
    &:after {
      content: "";
      width: 100%;
      height: 1px;
      background-color: var(--border-color);
      position: absolute;
      bottom: -10px;
      left: 0;
    }
  }
  ul {
    list-style-type: none;
    li {
      display: flex;
      padding: 2px;
      height: 80px;
      position: relative;
      cursor: pointer;

      &.selected {
        background: var(--background-color-chat);
        border-radius: 5px;
        border: solid var(--border-color) 1px;
      }

      span.unread {
        background: var(--theme_color);
        color: #fff;
        position: absolute;
        right: 11px;
        top: 20px;
        display: flex;
        font-weight: 700;
        min-width: 20px;
        justify-content: center;
        align-items: center;
        line-height: 20px;
        font-size: 12px;
        padding: 0 4px;
        border-radius: 10px;
      }
      .avatar {
        flex: 1;
        display: flex;
        align-items: center;
        position: relative;
        .status {
          border-radius: 50%;
          width: 12px;
          height: 12px;
          position: absolute;
          right: 15px;
          bottom: 16px;
          border: solid 2px white;
          &.offline {
            background-color: grey;
          }
          &.inactive {
            background-color: red;
          }
          &.online {
            background-color: green;
          }
        }
        .img {
          width: 40px;
          height: 40px;
          background-size: cover;
          border-radius: 50%;
          margin: 0 auto;
        }
      }
      .contact {
        flex: 3;
        font-size: 10px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;

        p {
          margin: 0;

          &.name {
            font-weight: bold;
          }
          &.email {
            color: var(--font-color-secondary);
            line-break: anywhere;
          }
        }
      }
    }
  }
}
.phone-ph-circle {
    width: 160px;
    height: 160px;
    top: 20px;
    left: 20px;
    position: absolute;
    background-color: transparent;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    border: 2px solid rgba(30, 30, 30, 0.4);
    opacity: .1;
    -webkit-animation: phone-circle-anim 1.2s infinite ease-in-out;
    -moz-animation: phone-circle-anim 1.2s infinite ease-in-out;
    -ms-animation: phone-circle-anim 1.2s infinite ease-in-out;
    -o-animation: phone-circle-anim 1.2s infinite ease-in-out;
    animation: phone-circle-anim 1.2s infinite ease-in-out;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.btn-phone {
    background-color: transparent;
	border:0;
	height:200px;
	width:200px;
    cursor: pointer;
    z-index: 200000 !important;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0);
    -webkit-transition: visibility .5s;
    -moz-transition: visibility .5s;
    -o-transition: visibility .5s;
    transition: visibility .5s;
	&:focus {
	    outline: none;
	}
}

.btn-phone.accept .phone-ph-circle-fill {
    background-color: rgba(51, 168, 10, 0.5);
	opacity: .75 !important;
}

.phone-ph-circle-fill {
    width: 100px;
    height: 100px;
    top: 50px;
    left: 50px;
    position: absolute;
    background-color: #000;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    border: 2px solid transparent;
    opacity: .1;
    -webkit-animation: phone-circle-fill-anim 2.3s infinite ease-in-out;
    -moz-animation: phone-circle-fill-anim 2.3s infinite ease-in-out;
    -ms-animation: phone-circle-fill-anim 2.3s infinite ease-in-out;
    -o-animation: phone-circle-fill-anim 2.3s infinite ease-in-out;
    animation: phone-circle-fill-anim 2.3s infinite ease-in-out;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.phone-ph-img-circle {
    width: 60px;
    height: 60px;
    top: 70px;
    left: 70px;
    position: absolute;
    background: rgba(30, 30, 30, 0.1);
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    border: 2px solid transparent;
    -webkit-animation: phone-circle-img-anim 1s infinite ease-in-out;
    -moz-animation: phone-circle-img-anim 1s infinite ease-in-out;
    -ms-animation: phone-circle-img-anim 1s infinite ease-in-out;
    -o-animation: phone-circle-img-anim 1s infinite ease-in-out;
    animation: phone-circle-img-anim 1s infinite ease-in-out;
}

.btn-phone.accept .phone-ph-img-circle {
    background-color: #33a867;
	text-align: center;
	i
	{
		font-size: 2em;
		line-height: 2em;
		color: white;
	}
}

.btn-phone.accept .phone-ph-circle {
    border-color: #33a867;
    opacity: .5;
}

.btn-phone.accept.phone-hover .phone-ph-circle-fill,
.btn-phone.accept:hover .phone-ph-circle-fill {
    background-color: #33a867;
    opacity: 1 !important;
}

.btn-phone.accept.phone-hover .phone-ph-img-circle,
.btn-phone.accept:hover .phone-ph-img-circle {
    background-color: white;
	i
	{
		color: #33a867;
	}
}
.btn-phone.deny
{
	height: 60px;
}
.btn-phone.deny .phone-ph-img-circle {
    background-color: #ff3b30;
	text-align: center;
	top:0;

	i
	{
		font-size: 2em;
		line-height: 2em;
		color: white;
	}
	-webkit-animation: none;
	-moz-animation: none;
	-ms-animation: none;
	-o-animation: none;
	animation: none;
}

.btn-phone.deny.phone-hover .phone-ph-img-circle,
.btn-phone.deny:hover .phone-ph-img-circle {
    background-color: white;
	i
	{
		color: #ff3b30;
	}
}

@-moz-keyframes phone-circle-anim {
    0% {
        transform: rotate(0) scale(.5) skew(1deg);
        opacity: .1
    }
    30% {
        transform: rotate(0) scale(.7) skew(1deg);
        opacity: .5
    }
    100% {
        transform: rotate(0) scale(1) skew(1deg);
        opacity: .1
    }
}

@-webkit-keyframes phone-circle-anim {
    0% {
        transform: rotate(0) scale(.5) skew(1deg);
        opacity: .1
    }
    30% {
        transform: rotate(0) scale(.7) skew(1deg);
        opacity: .5
    }
    100% {
        transform: rotate(0) scale(1) skew(1deg);
        opacity: .1
    }
}

@-o-keyframes phone-circle-anim {
    0% {
        transform: rotate(0) scale(.5) skew(1deg);
        opacity: .1
    }
    30% {
        transform: rotate(0) scale(.7) skew(1deg);
        opacity: .5
    }
    100% {
        transform: rotate(0) scale(1) skew(1deg);
        opacity: .1
    }
}

@keyframes phone-circle-anim {
    0% {
        transform: rotate(0) scale(.5) skew(1deg);
        opacity: .1
    }
    30% {
        transform: rotate(0) scale(.7) skew(1deg);
        opacity: .5
    }
    100% {
        transform: rotate(0) scale(1) skew(1deg);
        opacity: .1
    }
}

@-moz-keyframes phone-circle-fill-anim {
    0% {
        transform: rotate(0) scale(.7) skew(1deg);
        opacity: .2
    }
    50% {
        transform: rotate(0) scale(1) skew(1deg);
        opacity: .2
    }
    100% {
        transform: rotate(0) scale(.7) skew(1deg);
        opacity: .2
    }
}

@-webkit-keyframes phone-circle-fill-anim {
    0% {
        transform: rotate(0) scale(.7) skew(1deg);
        opacity: .2
    }
    50% {
        transform: rotate(0) scale(1) skew(1deg);
        opacity: .2
    }
    100% {
        transform: rotate(0) scale(.7) skew(1deg);
        opacity: .2
    }
}

@-o-keyframes phone-circle-fill-anim {
    0% {
        transform: rotate(0) scale(.7) skew(1deg);
        opacity: .2
    }
    50% {
        transform: rotate(0) scale(1) skew(1deg);
        opacity: .2
    }
    100% {
        transform: rotate(0) scale(.7) skew(1deg);
        opacity: .2
    }
}

@keyframes phone-circle-fill-anim {
    0% {
        transform: rotate(0) scale(.7) skew(1deg);
        opacity: .2
    }
    50% {
        transform: rotate(0) scale(1) skew(1deg);
        opacity: .2
    }
    100% {
        transform: rotate(0) scale(.7) skew(1deg);
        opacity: .2
    }
}

@-moz-keyframes phone-circle-img-anim {
    0% {
        transform: rotate(0) scale(1) skew(1deg)
    }
    10% {
        transform: rotate(-25deg) scale(1) skew(1deg)
    }
    20% {
        transform: rotate(25deg) scale(1) skew(1deg)
    }
    30% {
        transform: rotate(-25deg) scale(1) skew(1deg)
    }
    40% {
        transform: rotate(25deg) scale(1) skew(1deg)
    }
    50% {
        transform: rotate(0) scale(1) skew(1deg)
    }
    100% {
        transform: rotate(0) scale(1) skew(1deg)
    }
}

@-webkit-keyframes phone-circle-img-anim {
    0% {
        transform: rotate(0) scale(1) skew(1deg)
    }
    10% {
        transform: rotate(-25deg) scale(1) skew(1deg)
    }
    20% {
        transform: rotate(25deg) scale(1) skew(1deg)
    }
    30% {
        transform: rotate(-25deg) scale(1) skew(1deg)
    }
    40% {
        transform: rotate(25deg) scale(1) skew(1deg)
    }
    50% {
        transform: rotate(0) scale(1) skew(1deg)
    }
    100% {
        transform: rotate(0) scale(1) skew(1deg)
    }
}

@-o-keyframes phone-circle-img-anim {
    0% {
        transform: rotate(0) scale(1) skew(1deg)
    }
    10% {
        transform: rotate(-25deg) scale(1) skew(1deg)
    }
    20% {
        transform: rotate(25deg) scale(1) skew(1deg)
    }
    30% {
        transform: rotate(-25deg) scale(1) skew(1deg)
    }
    40% {
        transform: rotate(25deg) scale(1) skew(1deg)
    }
    50% {
        transform: rotate(0) scale(1) skew(1deg)
    }
    100% {
        transform: rotate(0) scale(1) skew(1deg)
    }
}

@keyframes phone-circle-img-anim {
    0% {
        transform: rotate(0) scale(1) skew(1deg)
    }
    10% {
        transform: rotate(-25deg) scale(1) skew(1deg)
    }
    20% {
        transform: rotate(25deg) scale(1) skew(1deg)
    }
    30% {
        transform: rotate(-25deg) scale(1) skew(1deg)
    }
    40% {
        transform: rotate(25deg) scale(1) skew(1deg)
    }
    50% {
        transform: rotate(0) scale(1) skew(1deg)
    }
    100% {
        transform: rotate(0) scale(1) skew(1deg)
    }
}
</style>
