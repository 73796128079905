require('./bootstrap');

"use strict";

export async function changeRoom(roomUrl){
    //Fetch new page as string
    let newPage = await (await fetch(roomUrl)).text();
    newPage = newPage.trim();

    //Create template element which doesn't appear in DOM
    let template = document.createElement('template');
    template.innerHTML = newPage; //Set innerhtml

    //Fetch the #content .container element (main content)
    let newPageContentElement = template.content.querySelector('#content');

    //Append content to the shadowRoom div
    const shadowRoom = document.getElementById('shadowRoom');
    shadowRoom.append(...newPageContentElement.childNodes); //childNodes is iterable so it can be spread with ... to become multiple parameters of .append()

    Array.from(shadowRoom.querySelectorAll("script")).forEach( oldScript => {
        const newScript = document.createElement("script");
        Array.from(oldScript.attributes)
            .forEach( attr => newScript.setAttribute(attr.name, attr.value) );
        newScript.appendChild(document.createTextNode(oldScript.innerHTML));
        oldScript.parentNode.replaceChild(newScript, oldScript);
    });

    const contentRoom = document.getElementById('content');
   contentRoom.classList.add('fade-out');
   console.log("fadeout");
    console.log("loadRoom start");
    await loadRoom();
    console.log("loadRoom finished");
   shadowRoom.style.opacity = 0.0;
   contentRoom.style.opacity = 1.0;
   contentRoom.classList.remove('fade-out');
   shadowRoom.classList.add('fade-in');

   setTimeout(function(){
        shadowRoom.style.opacity = 1.0;
        contentRoom.style.opacity = 0.0;
        shadowRoom.classList.remove('fade-in');
        contentRoom.classList.remove('fade-out');
        shadowRoom.classList.remove('fade-in');
    }, 500);


    contentRoom.id = "shadowRoom";
    shadowRoom.id = "content";
    contentRoom.innerHTML = "";
    history.pushState({}, 'Room Test', roomUrl);
    window.dispatchEvent(new Event('updatenavi'));


}

window.addEventListener('popstate', (event) => {
    console.log("popstate");
    changeRoom(document.location);
});

export async function loadRoom(static_load)
{
    //console.log("loadRoom");

    await (await import(/* webpackMode: "eager" */ `../../resources/js/room.js`)).default(static_load);


}

export async function loadGlobalModules()
{
    let modules = JSON.parse(document.getElementById("global_room_data").dataset.modules);
  await Promise.all(modules.map(async (module) => {
      if(module.preload_required == 1)
      {
          await App.loadModule(module.shortname,"global",module.pivot.id,module.pivot.metadata.settings);
      }
      else {
          App.loadModule(module.shortname,"global",module.pivot.id,module.pivot.metadata.settings);
      }
  }));
}

//https://webpack.js.org/guides/lazy-loading/#example
export async function loadModule(name,room,id,settings)
{
    //console.log("load Module"+name);
    await (await import(/* webpackMode: "eager" */  `../../storage/app/Modules/${name}/resources/js/module`)).default(id,room,settings);

}
export function loadedModule(name,room,id)
{
    //console.log("loaded Module"+name);
}
document.addEventListener("DOMContentLoaded", function(event) {
    loadRoom(true);
    loadGlobalModules();
});
