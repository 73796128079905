require('../../../../../../resources/js/bootstrap');
import { changeRoom } from '../../../../../../resources/js/frontend';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/photoswipe.css';
let navi;
export default async (modul_id, actualRoom, settings) => {

    const data = await (await fetch(`/gallery/${actualRoom}/module/PortfolioGallery/api?action=navigation`)).json();
    const axios = require('axios').default;

    await buildMapNavigation(data.navi, modul_id);

    console.log('%c 📍 PortfolioGalleryModule Loaded! ', 'background: #222; color: #bada55');

}

function buildMapNavigation (roomList, modul_id) {
    navi = document.getElementById('portfoliogallery_' + modul_id + '_items');
    const options = {
        gallery: '#portfoliogallery_' + modul_id + '_items',
        children: '.popup-btn',
        pswpModule: () => import('photoswipe'),
        bgOpacity: 0.9,
        returnFocus: true,
    };
    const lightbox = new PhotoSwipeLightbox(options);


    for (let i = 0; i < roomList.length; i++) {
        const room = roomList[i];
        const naviTemplate = document.getElementById('portfoliogallery_' + modul_id + '_element');
        let clone = document.importNode(naviTemplate.content, true);
        let link = clone.querySelector(".popup-btn");
        let image = clone.querySelector("img");
        
        link.dataset.slide_id = i;

        link.dataset.image = room.image;
        link.dataset.class = room.class;
        link.dataset.popup_image = room.image;
        link.dataset.cropped = true;
        link.dataset.gallery_item_id = modul_id + "_" + i;

        if (room.class != null) {
            link.classList.add(room.class);
        }
        link.style = link.style += (room.css ?? "");
        switch (room.type) {
            case "image":
                axios.get(`/gallery/${actualRoom}/module/PortfolioGallery/public?action=metadata&image_id=${room.image_id}`)
                .then(response => {
                    //console.log(response.data);
                    link.dataset.pswpWidth = response.data[0];
                    link.dataset.pswpHeight = response.data[1];
  });
                link.title = room.name ?? "";
                link.dataset.image_id = room.image_id;
                
                image.src = `/gallery/${actualRoom}/module/PortfolioGallery/public?action=thumbnail&image_id=${room.image_id}`;
                link.href = `/gallery/${actualRoom}/module/PortfolioGallery/public?action=image&image_id=${room.image_id}`;
                var img = new Image();
                img.onload = function () {
                    link.dataset.pswpWidth = this.width;
                    link.dataset.pswpHeight = this.height;
                };
                img.loading = "lazy";
                img.src = `/gallery/${actualRoom}/module/PortfolioGallery/public?action=image&image_id=${room.image_id}`;

        }

        navi.appendChild(clone);

    }
    lightbox.init();

}



