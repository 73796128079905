require('../../../../../../resources/js/bootstrap');
import { changeRoom } from '../../../../../../resources/js/frontend';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/photoswipe.css';
let navi;
export default async (modul_id, actualRoom, settings) => {

    const data = await (await fetch(`/gallery/${actualRoom}/module/DownloadGallery/api?action=navigation`)).json();
    const axios = require('axios').default;

    await buildMapNavigation(data.navi, modul_id);

    console.log('%c 📍 DownloadGalleryModule Loaded! ', 'background: #222; color: #bada55');

}


function buildMapNavigation (roomList, modul_id) {
    
    navi = document.getElementById('downloadgallery_' + modul_id + '_items');
    const options = {
        gallery: '#downloadgallery_' + modul_id + '_items',
        children: '.popup-btn',
        pswpModule: () => import('photoswipe'),
        bgOpacity: 0.9,
        returnFocus: true,
    };
    const lightbox = new PhotoSwipeLightbox(options);
    lightbox.on('uiRegister', function () {
        lightbox.pswp.ui.registerElement({
            name: 'download-button',
            order: 8,
            isButton: true,
            tagName: 'a',
            html: {
                isCustomSVG: true,
                inner: '<path d="M20.5 14.3 17.1 18V10h-2.2v7.9l-3.4-3.6L10 16l6 6.1 6-6.1ZM23 23H9v2h14Z" id="pswp__icn-download"/>',
                outlineID: 'pswp__icn-download'
            },
            onInit: (el, pswp) => {
                el.setAttribute('download', '');
                el.setAttribute('target', '_blank');
                el.setAttribute('rel', 'noopener');

                pswp.on('change', () => {
                    el.href = pswp.currSlide.data.src;
                    el.setAttribute('download', pswp.currSlide.content.data.element.dataset.image);
                });
            }
        });
    });


    for (let i = 0; i < roomList.length; i++) {
        const room = roomList[i];
        const naviTemplate = document.getElementById('downloadgallery_' + modul_id + '_element');
        let clone = document.importNode(naviTemplate.content, true);
        let link = clone.querySelector(".popup-btn");
        let image = clone.querySelector("img");
        let button = clone.querySelector(".btn-download");

        link.dataset.slide_id = i;

        link.dataset.image = room.image;
        link.dataset.class = room.class;
        link.dataset.popup_image = room.image;
        link.dataset.cropped = true;
        link.dataset.gallery_item_id = modul_id + "_" + i;

        if (room.class != null) {
            link.classList.add(room.class);
        }
        link.style = link.style += (room.css ?? "");
        switch (room.type) {
            case "image":
                axios.get(`/gallery/${actualRoom}/module/DownloadGallery/public?action=metadata&image_id=${room.image_id}`)
                .then(response => {
                    //console.log(response.data);
                    link.dataset.pswpWidth = response.data[0];
                    link.dataset.pswpHeight = response.data[1];
  });
                link.title = room.name ?? "";
                link.dataset.image_id = room.image_id;
                button.download = room.image;
                button.href = `/gallery/${actualRoom}/module/DownloadGallery/public?action=download&image_id=${room.image_id}`;
                image.src = `/gallery/${actualRoom}/module/DownloadGallery/public?action=thumbnail&image_id=${room.image_id}`;
                link.href = `/gallery/${actualRoom}/module/DownloadGallery/public?action=download&image_id=${room.image_id}`;
                var img = new Image();
                img.onload = function () {
                    link.dataset.pswpWidth = this.width;
                    link.dataset.pswpHeight = this.height;
                };
                img.loading = "lazy";
                img.src = `/gallery/${actualRoom}/module/DownloadGallery/public?action=image&image_id=${room.image_id}`;

        }

        navi.appendChild(clone);

    }
    lightbox.init();

}



