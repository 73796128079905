export default async (modul_id,actualRoom,settings) =>
{
    console.log('%c ⏱️Countdown Module Loaded! ', 'background: #222; color: #bada55');

    let  module_settings = settings;
    if(module_settings.hide_other_modules == "1")
    {
    let modules = JSON.parse(document.getElementById("room_data").dataset.modules);

      await Promise.all(modules.map(async (module) => {
         console.log(module);
         if(module.shortname == "Countdown") return;
         document.querySelector(`[data-module-relation-id='${module.pivot.id}']`).style.display="none";
      }));
    }
    /*Echo.private(`room.${actualRoom}.module.Countdown.settings`)
   .listen('ModuleSettingsUpdate', async (event) => {
       console.log("ModuleSettingsUpdate", event);
       module_settings = event.settings;
       clearInterval(x);

   });
*/
   //console.log(`[data-module-relation-id='${modul_id}'] [data-countdown-timer]`);
    //   document.querySelector(`[data-module-relation-id='${modul_id}'] [data-countdown-timer]`).innerHTML =  (hours<10?"0":"")+hours + ":" + (minutes<10?"0":"")+minutes + ":" + (seconds<10?"0":"")+seconds;

   // Set the date we're counting down to
   var countDownDate = new Date(module_settings.end_datetime).getTime();

   // Update the count down every 1 second
   var x = setInterval(async function() {

     // Get today's date and time
     var now = new Date().getTime();

     // Find the distance between now and the count down date
     var distance = countDownDate - now;


    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
    var tag_days = "<span class='days'><label>"+((days == 1) ? "Tag" : "Tage")+"</label>"+((days < 10 && days >0) ? "0"+days : ((days > 0) ? days : "0"))+"</span><span class='delimiter'></span>";
    var tag_hours = "<span class='hours'><label>"+((hours == 1) ? "Stunde" : "Stunden")+"</label>"+((hours < 10 && hours >0) ? "0"+hours : ((hours > 0) ? hours : "0"))+"</span><span class='delimiter'></span>";
    var tag_minutes = "<span class='minutes'><label>"+((minutes == 1) ? "Minute" : "Minuten")+"</label>"+((minutes < 10 && minutes >0) ? "0"+minutes : ((minutes > 0) ? minutes : "0"))+"</span><span class='delimiter'></span>";
    var tag_seconds = "<span class='seconds'><label>"+((seconds == 1) ? "Sekunde" : "Sekunden")+"</label>"+((seconds < 10 && seconds >0) ? "0"+seconds : ((seconds > 0) ? seconds : "0"))+"</span>";

    let timer_element = document.querySelector(`[data-module-relation-id='${modul_id}'] [data-countdown-timer]`);
    //console.log(timer_element);
    if(timer_element === null)
    {
        clearInterval(x);
        return;
    }
    timer_element.innerHTML = tag_days + tag_hours + tag_minutes + tag_seconds;


     // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(x);
      // document.querySelector(`[data-module-relation-id='${modul_id}'] [data-countdown-timer]`).innerHTML = "EXPIRED";
       const forward_room_slug = document.querySelector(`[data-module-relation-id='${modul_id}']`).dataset.forwardRoomSlug;
       if(module_settings.room != "" && module_settings.room != null )
       {
           window.location.href = "/room/"+forward_room_slug;
       }
       if(module_settings.hide_other_modules == "1")
       {
           console.log("hide other modules");
           let modules = JSON.parse(document.getElementById("room_data").dataset.modules);

             await Promise.all(modules.map(async (module) => {
                console.log(module);
                if(module.shortname == "Countdown") return;
                document.querySelector(`[data-module-relation-id='${module.pivot.id}']`).style.display="block";
             }));
       }
      }

 }, 1000);

};
