require('../../../../../../resources/js/bootstrap');
import {changeRoom} from '../../../../../../resources/js/frontend';
let agenda
export default async (modul_id,actualRoom,settings) =>
{
    console.log('%c 🧭 AgendaModule Loaded! ', 'background: #222; color: #bada55');
    const data = await (await fetch(`/gallery/${actualRoom}/module/Agenda/api?action=navigation`)).json();
    //console.log(data);
    buildNavigation(data, modul_id);

    Echo.channel('activities')
    .listen('NavigationUpdate', async(roomListEvent) => {
        const data = await (await fetch(`/gallery/${actualRoom}/module/Agenda/api?action=navigation`)).json();
        buildNavigation(data, modul_id);
    });
};
window.addEventListener('updatenavi', function(){

    if(agenda.querySelector(".active"))
    {
      agenda.querySelector(".active").dataset.active= false;
      agenda.querySelector(".active").classList.remove("active");
    }

    if(agenda.querySelector(`[data-room='${actualRoom}']`)?.dataset.active == "false")
    {
        agenda.querySelector(`[data-room='${actualRoom}']`).dataset.active = true;
        agenda.querySelector(`[data-room='${actualRoom}']`).classList.add("active");
    }

  //  console.log("Received global naviupdate ", event);
});

function buildNavigation(roomList, modul_id){

    //console.log("buildNavigation");
    agenda = document.createElement
    agenda = document.getElementById("agenda_"+modul_id);

    agenda.innerHTML = "";
    //console.log("#navigation-element_"+modul_id);

    for(let i = 0; i < roomList.length; i++){
        //console.log(actualRoom+" == "+roomList[i].object.slug);

        const room = roomList[i]; //class, target name, type, value, object

        //console.log(room);
        const naviTemplate = document.getElementById("navigation-element_"+modul_id);

        let clone = document.importNode(naviTemplate.content, true);

        let link = clone.getElementById("agenda__time");
        if(room.timeE){
          link.innerHTML = room.timeS + ' - ' + room.timeE;
        }
        else{
          link.innerHTML = room.timeS;
        };
        
        let link2 = clone.getElementById("agenda__description");
        link2.querySelector("#agenda__headline").innerHTML = '<b>' + room.headline + '</b>';
        link2.querySelector("#agenda__subline").innerHTML = room.subline;
        let link3 = clone.getElementById("agenda__presenter");
        link3.innerHTML = room.presenter;
        agenda.appendChild(clone);

    }
}