require('../../../../../../resources/js/bootstrap');

"use strict";

let notification_sound;
let current_settings;
async function setupModule(actualRoom)
{
    const data = await (await fetch(`/room/${actualRoom}/module/PushAnnouncement/api?action=metadata`)).json();
    current_settings = data.settings //enabled focus message
    console.log(data);

    if(current_settings.audio_play == 1 && current_settings.mp3 != "") notification_sound = new Audio(`/room/${actualRoom}/module/PushAnnouncement/public?action=mp3`);
    [].forEach.call(data.user.groups, function(group) {
if(data.group_everyone === group["id"])
{
    console.log("everyone");
    Echo.channel(`room.${actualRoom}.module.PushAnnouncement.group.${group["id"]}.update`)
    .listen('.Modules\\PushAnnouncement\\Events\\PushAnnouncement', async (event) => {
       console.log("PushAnnouncement", event);
       pushMessageToChatHistory(event.user,event.message)
    });
}
else {
    console.log("private group");
    console.log(`room.${actualRoom}.module.PushAnnouncement.group.${group["id"]}.update`);
    Echo.private(`room.${actualRoom}.module.PushAnnouncement.group.${group["id"]}.update`)
    .listen('.Modules\\PushAnnouncement\\Events\\PushAnnouncement', async (event) => {
       console.log("PushAnnouncement", event);
       pushMessageToChatHistory(event.user,event.message)
    });
}

      });

}

async function leaveCall()
{
    //console.log("leaveCall");
//    document.querySelector(".ui-controller").classList.remove("show");
//    call_frame?.leave();
//    disableChat();
    //call_frame?.destroy();
    //Echo.leaveChannel(`private-room.${actualRoom}.module.Question.user.${current_user.id}.settingsChanged`);

}

function pushMessageToChatHistory(user,message)
{
  var currentdate = new Date();
  var currenttime = currentdate.getHours() + ":" + currentdate.getMinutes() + ":" + currentdate.getSeconds();

  var push_message_template = document.querySelector('#push_message').content.cloneNode(true);
  push_message_template.querySelector("img").src = "/image/user/"+user.id;
  push_message_template.querySelector(".username").textContent = user.forename + " " + user.surname;
  push_message_template.querySelector(".timestamp").textContent = currenttime;
  push_message_template.querySelector(".message").innerHTML = message;

  var push_history = document.getElementById("push_history");
  push_history.prepend(push_message_template);

  var toastElList = [].slice.call(document.querySelectorAll('.toast'))
        var toastList = toastElList.map(function(toastEl) {
        // Creates an array of toasts (it only initializes them)
          return new bootstrap.Toast(toastEl) // No need for options; use the default options
        });
       toastList.forEach(toast => {
           toast.show();
          toast._element.addEventListener('hidden.bs.toast', function () {
                this.remove();
            });
       });
       try{
           if(current_settings.audio_play == 1 && current_settings.mp3 != "") notification_sound.play();
        }
        catch(e)
        {
            console.log(e);
        }
}

export default async(modul_id,actualRoom,settings) =>
{
    console.log('%c 🔔 PushAnnouncementModule Loaded! ', 'background: #222; color: #bada55');
    setupModule(actualRoom);
    App.loadedModule(modul_id,actualRoom);
}
