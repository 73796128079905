export default async (modul_id,actualRoom,settings) =>
{
    console.log('%c 📄 HTML-Code Module Loaded! ', 'background: #222; color: #bada55');

    Echo.private(`room.${actualRoom}.module.HtmlContent.settings`)
   .listen('ModuleSettingsUpdate', async (event) => {
       console.log("ModuleSettingsUpdate", event);
       if(event.settings.enabled === "0")
       {
          document.querySelector(`[data-module-relation-id='${modul_id}'] [data-module-content]`).innerHTML = "";
       }
       else {
           const data = await (await fetch(`/room/${actualRoom}/module/HTMLContent?api`)).json();
           //console.log(data.compiled_html);
         //  console.log((`[data-module-relation-id='${modul_id}'] [data-module-content]`),event.settings);
          document.querySelector(`[data-module-relation-id='${modul_id}'] [data-module-content]`).innerHTML = data.compiled_html;

       }

   });

};
