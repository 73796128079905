<template>
    <div :class="`chat-app ${chatVisible ? 'expanded' : ''}`">
		<div class="buttonContainer">
			<button v-on:click="toggleChat" class="toggleChat btn btn-primary"><i v-if="chatVisible" class="far fa-arrow-right"></i><i v-else class="far fa-comments"></i><span v-if="unreadCount > 0" class="badge">{{ unreadCount }}</span></button>
		</div>
		<Conversation ref="Conversation" @setIsCallingFalse="setIsCallingFalse" @setIsTalkingFalse="setIsTalkingFalse" @setIsCallingTrue="setIsCallingTrue" @setIsTalkingTrue="setIsTalkingTrue" :isCalling="isCalling" :isTalking="isTalking" :callingContact="callingContact" :contact="selectedContact" :messages="messages" :typingUser="typingUser" :user="user" :chatVisible="chatVisible" @new="saveNewMessage" @denyCall="denyCall" @resetCall="resetCall" @denyCallParticipant="denyCallParticipant" @acceptCall="acceptCall" @callEstablished="callEstablished" @closeConversation="closeConversation" @setCallingContact="setCallingContact" v-if="chatVisible && conversationVisible"/>
        <ContactsList ref="ContactsList" :isCalling="isCalling" :isTalking="isTalking" :callingContact="callingContact" :contacts="contacts" @selected="startConversationWith" v-if="chatVisible" @denyCall="denyCall" @acceptCall="acceptCall" @cancelCall="cancelCall"/>
    </div>
</template>

<script>
    import Conversation from './Conversation';
    import ContactsList from './ContactsList';

    export default {
		props: {
			user: {
				type: Object,
				required: true
			},
			unreadCountApi: {
				required: true
			},
		},
		data() {
			return {
				selectedContact: null,
				messages: [],
				contacts: [],
				typingUser: false,
				typingTimer: false,
				chatVisible: false,
				conversationVisible: false,
				unreadCount: 0,
				callingAudio:null,
				callingContact: null,
				isCalling: false,
				isTalking: false
            };
        },
        mounted() {
			console.log(this.settings)

			this.unreadCount = this.unreadCountApi;
			this.callingAudio = new Audio(`/room/${this.module_room_name}/module/Chat/public?action=call_notification_file`);
			this.callingAudio.loop = true;

			Echo.join(`room.${this.module_room_name}.module.Chat.update`)
                .here(users => {
					users.forEach((user) => {
						this.updateUserList(user,"online");
					});
                })
                .joining(user => {
					this.updateUserList(user,"online");
                })
                .leaving(user => {
                    this.updateUserList(user,"inactive");
                })
            Echo.private(`room.${this.module_room_name}.module.Chat.user.${this.user.id}.update`)
                .listen('.Modules\\Chat\\Events\\NewMessage', (e) => {
                    this.handleIncoming(e.message);
					if(this.settings.chat_notification === "1" && this.settings.chat_notification_file != "" && (this.selectedContact === null || this.selectedContact.id != e.message.user_source))
 				   {
					var audio = new Audio(`/room/${this.module_room_name}/module/Chat/public?action=chat_notification_file`);
					 audio.play();
				 	}
                });
				Echo.private(`room.${this.module_room_name}.module.Chat.user.${this.user.id}.whisper`)
				.listenForWhisper('typing', (typing_user) => {
					if(this.selectedContact === null || typing_user.id != this.selectedContact.id) return;
                   		this.typingUser = typing_user;
                    if(this.typingTimer) {
                        clearTimeout(this.typingTimer);
                    }

                   	this.typingTimer = setTimeout(() => {
                       this.typingUser = false;
                   }, 3000);

			   }).listenForWhisper('calling', (call) => {
				   console.log(call);
				   console.log(this.isTalking+" || "+	this.isCalling);
				   if(this.isTalking || this.isCalling)
				   {
					   console.log("already calling")
					   Echo.private(`room.${this.module_room_name}.module.Chat.user.${call.user.id}.whisper`)
						   .whisper('cancel-calling', {"user":this.user,"roomUrl":this.roomUrl});
				   }
				   else
				   {
					   if(!this.chatVisible) this.chatVisible = true;

					   this.callingContact = call.user;
					   this.isCalling = true;
					   console.log(this.callingContact);

					   if(this.selectedContact === null || this.selectedContact.id !== call.user.id)
					   {
						   console.log("Contact nicht ausgewählt oder anderer");
						   	this.$nextTick(() => {
						   		this.$refs.ContactsList.receiveCalling(call.roomUrl);
					   		});
							if(this.settings.call_notification === "1" && this.settings.call_notification_file != "")
							 {
								 this.callingAudio.play();

							 }
					   }
					   else
					   {

						   this.$refs.Conversation.receiveCalling(call.roomUrl);
					   }
			   	   }

			   }).listenForWhisper('cancel-calling', (call) => {
				   this.isCalling = false;
				   this.isTalking = false;
				   this.$nextTick(() => {

				   if(this.selectedContact === null || this.selectedContact.id !== call.user.id)
					{
				 	this.$refs.ContactsList.cancelCalling();
				} else
				{
					this.$refs.Conversation.cancelCalling();

									}
					});
			   });
            axios.get(`/room/${this.module_room_name}/module/Chat/api?action=contacts`)
                .then((response) => {
                    this.contacts = response.data;
                });
        },
        methods: {
			closeConversation()
			{
				console.log("Close conversation in main");
				this.conversationVisible = false;
				this.selectedContact = null;
				this.$refs.ContactsList.clearSelected();
			},
			toggleChat()
			{
				console.log("toggleChat");
				if(this.isCalling || this.isTalking)
				{
				var r = confirm("The call will be ended, if you close the chat window!");
				if (r == false) {
					return;
				}
					this.$refs.Conversation.cancelCall();
					this.isTalking = false;
					this.isCalling = false;
					this.callingContact = null;
				}
				this.chatVisible = !this.chatVisible;
			},
			setCallingContact(contact)
			{
				this.callingContact = contact;
			},
			cancelCall()
			{
				console.log("CANCEL CALL FROM MAIN");
				this.$refs.Conversation.cancelCall();
			},
			setIsCallingTrue()
			{
				console.log("IS CALLING IN MAIN")
				this.isCalling = true;
			},
			setIsTalkingTrue()
			{
				this.isTalking = true;
			},
			setIsCallingFalse()
			{
				this.isCalling = false;
			},
			setIsTalkingFalse()
			{
				this.isTalking = false;
			},
			callEstablished()
			{
				this.isTalking = true;
				this.isCalling = false;
				console.log("call established");
			},
            startConversationWith(new_contact) {
				this.conversationVisible = true;
                this.updateUnreadCount(new_contact, true);

                axios.get(`/room/${this.module_room_name}/module/Chat/api?action=conversation&contact=${new_contact.id}`)
                    .then((response) => {
                        this.messages = response.data;
                        this.selectedContact = new_contact;
                    })
            },
            saveNewMessage(message) {
				this.messages.push(message);

				for (let i = 0; i < this.contacts.length; i++)
				{
						if(this.contacts[i].id === message.user_target)
						{
							if(this.contacts[i].contacted === false)
							{
								this.contacts[i].contacted = true;
							}
							break;
						}
				}
			},
		    handleIncoming(message) {

				this.updateUnreadCount(message.from_contact, false);

                if (this.selectedContact && message.user_source == this.selectedContact.id) {
                    this.saveNewMessage(message);
                    return;
                }

            },
			updateUserList(contact,status)
			{
				this.contacts = this.contacts.map((single) => {
					if (single.id !== contact.id) {
						return single;
					}
					single.status = status;
					return single;
				})
			},
            updateUnreadCount(contact, reset) {
				//console.log(contact);
				if(reset)
				{
					this.updateGlobalUnreadCount(contact.unread*-1);
				}
				else
				{
					this.updateGlobalUnreadCount(1);
				}
				//this.unreadCount = this.unreadCount+1;
                this.contacts = this.contacts.map((single) => {
                    if (single.id !== contact.id) {
                        return single;
                    }

                    if (reset)
                        single.unread = 0;
                    else
                        single.unread += 1;
                    return single;
                })
            },
			updateGlobalUnreadCount(number) {
				this.unreadCount += number;

				this.$nextTick(() => {
					if(document.querySelector(".toggleChat .badge") === null) return;
					document.querySelector(".toggleChat .badge").classList.add('message-shake');
					setTimeout(function(){
						if(document.querySelector(".toggleChat .badge") !== null ) document.querySelector(".toggleChat .badge").classList.remove('message-shake');
					}, 1000);
				});

		    },
			denyCall()
			{
				console.log("deny call from main");
				Echo.private(`room.${this.module_room_name}.module.Chat.user.${this.callingContact.id}.whisper`)
		 		   .whisper('cancel-calling', {"user":this.user,"roomUrl":this.roomUrl});
				this.isCalling = false;
				this.isTalking = false;
				this.callingContact = null;
				this.callingAudio.pause();
			},
			denyCallParticipant()
			{
				console.log("deny call participant from main");
				this.isCalling = false;
				this.isTalking = false;
				this.callingContact = null;
			},
			resetCall()
			{
				console.log("reset Call from main");
				this.isCalling = false;
				this.isTalking = false;
				this.callingContact = null;
			},
			acceptCall(url)
			{
				console.log("accept call from main");
				this.isCalling = false;
				this.isTalking = true;
				//this.callingContact = null;
				this.callingAudio.pause();
				this.$refs.Conversation.joinRoom(url);


			}
        },
        components: {Conversation, ContactsList}
    }
</script>
<style lang="scss" scoped>
.chat-app
	{
	display: flex;
	align-items:center;
	position: absolute;
	right: 0;
	margin: 0;
	padding: 0;
	top: 10%;
	height:80%;
	z-index:2;
	border-radius: 1.0em 0 0 1.0em;
	background-color: rgba(255,255,255,0.0);;
	transition: opacity background-color .5s;

	&.expanded
	{
		opacity: 1;
		transition: opacity background-color .5s;
        background-color: rgba(255,255,255,1.0);
		.toggleChat
		{
			   margin-left: -25px;
			   margin-right: 0px;
		}
	}
	.buttonContainer
	{
		/*position:relative;*/
	}
	.toggleChat
	{
	    border-radius: 50%;
	    border: 0;
	    background-color: var(--theme_color);
	    width: 50px;
	    height: 50px;
	    z-index: 2;
		position:relative;
		margin-right: 25px;
		.badge
		{
			position:absolute;
			background-color: var(--white);
			width:24px;
			height:24px;
			color: var(--theme_color);
			right:0;
			border-radius: 50%;
			padding:0;
			text-align:center;
			line-height:22px;
			font-size:10px;
			margin-top: -5px;
			margin-right: -5px;
			&.message-shake
			{
				animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
				transform: translate3d(0, 0, 0);
			}
		}
		&:focus {
		    background-color: var(--theme_color);
		    border: 0;
		    box-shadow: none;
		}
	}
}
@keyframes shake {
  10%, 90% {
	transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
	transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
	transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
	transform: translate3d(4px, 0, 0);
  }
}


</style>
