require('../../../../../../resources/js/bootstrap');
import {changeRoom} from '../../../../../../resources/js/frontend';
let navi
export default async (modul_id,actualRoom,settings) =>
{
    console.log('%c 🧭 NavigationModule Loaded! ', 'background: #222; color: #bada55');
    const data = await (await fetch(`/room/${actualRoom}/module/Navigation/api?action=navigation`)).json();
    //console.log(data);
    buildNavigation(data, modul_id);

    Echo.channel('activities')
    .listen('NavigationUpdate', async(roomListEvent) => {
        const data = await (await fetch(`/room/${actualRoom}/module/Navigation/api?action=navigation`)).json();
        buildNavigation(data, modul_id);
    });
};
window.addEventListener('updatenavi', function(){

    if(navi.querySelector(".active"))
    {
      navi.querySelector(".active").dataset.active= false;
      navi.querySelector(".active").classList.remove("active");
    }

    if(navi.querySelector(`[data-room='${actualRoom}']`)?.dataset.active == "false")
    {
        navi.querySelector(`[data-room='${actualRoom}']`).dataset.active = true;
        navi.querySelector(`[data-room='${actualRoom}']`).classList.add("active");
    }

    console.log("Received global naviupdate ", event);
});

function buildNavigation(roomList, modul_id){

    //console.log("buildNavigation");
    navi = document.createElement
    navi = document.getElementById("navigation_"+modul_id);

    navi.innerHTML = "";
    //console.log("#navigation-element_"+modul_id);

    for(let i = 0; i < roomList.length; i++){
        //console.log(actualRoom+" == "+roomList[i].object.slug);

        const room = roomList[i]; //class, target name, type, value, object

        //console.log(room);
        const naviTemplate = document.getElementById("navigation-element_"+modul_id);

        let clone = document.importNode(naviTemplate.content, true);

        let link = clone.querySelector("a");
        if(room.checkbox == 1){
          link.target = "_blank";
        }
        if(room.class != null){
          link.classList.add(room.class);
        }
        link.style = link.style += (room.css ?? "");

        link.dataset.active = false;

        if(room.type == "link"){
          link.innerHTML = room.name ?? room.value;
          link.href = room.value;
        }else if(room.type == "room"){
            if(actualRoom == roomList[i].object.slug)
            {
                link.dataset.active = true;
                link.classList.add("active");
            }
          link.innerHTML = room.name ?? room.object.longname;
          link.dataset.room = room.object.slug;
          link.addEventListener('click', (event) => {
              event.preventDefault();
              if(event.target.dataset.active == "true") return;

              changeRoom("/room/" +event.target.dataset.room);
              if(navi.querySelector(".active"))
              {
                navi.querySelector(".active").dataset.active= false;
                navi.querySelector(".active").classList.remove("active");
              }
              event.target.classList.add("active");
              event.target.dataset.active = true;
              console.log("Navi Click Event ", event);
          });

        }
        navi.appendChild(clone);

    }
}
