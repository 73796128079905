require('../../../../../../resources/js/bootstrap');

"use strict";

window.Vue = require('vue');

import VueChatScroll from 'vue-chat-scroll'
Vue.use(VueChatScroll)
export default async(modul_id,actualRoom, settings) =>
{
    console.log('%c 💬 CommentsModule Loaded! ', 'background: #222; color: #bada55');
    setupModule(modul_id,actualRoom,settings);
    App.loadedModule(modul_id,actualRoom);
}
async function setupModule(modul_id,room,settings)
{
	Vue.component('chat-app', require('../vue/ChatApp.vue').default);
	Vue.prototype.settings = settings;
	Vue.prototype.module_room_name = room;

	const app = new Vue({
	    el: '#'+room+"_"+modul_id
	});
	return;
}
