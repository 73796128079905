<template>
	<div ref="feed" class="feed_container">
		<div class="feed">
			<ul v-if="contact">
				<li v-for="message in messages" :class="`message${message.user_target == contact.id ? ' sent' : ' received'}`" :key="message.id">
					<div class="avatar"><div class="image"  :style="{'background-image':`url(/image/user/${message.user_source})`}"></div></div>
					<div class="text">{{ message.message }}</div>
					<div class="misc">
						<div class="date">{{ message.created_at | formatDate }}&nbsp;</div><div class="time">{{ message.created_at | formatTime }}</div>
					</div>
				</li>

			</ul>
			<div v-if="typingUser" class="typing">
			<div class="text">{{ typingUser.forename }} is typing...</div>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment'

	export default {
		props: {
			contact: {
				type: Object
			},
			messages: {
				type: Array,
				required: true
			},
			typingUser: {
				type: [Object,Boolean]
			},
			chatVisible: {
				type: Boolean,
				default: false
			}
		},
		methods: {
			scrollToBottom() {
				setTimeout(() => {
					this.$refs.feed.querySelector(".feed").scrollTop = this.$refs.feed.querySelector(".feed").scrollHeight - this.$refs.feed.querySelector(".feed").clientHeight;
				},50);
			},
		},
		filters:
		{
			formatDate: function(date) {
				if (date) {
					return moment(String(date)).format('MM.DD.YYYY')
				}
			},
			formatTime: function(date) {
				if (date) {
					return moment(String(date)).format('HH:mm')
				}
			}
		},
		watch: {
			contact(contact) {
				this.scrollToBottom();
			},
			messages(messages) {
				this.scrollToBottom();
			},
			chatVisible: {
				handler () {
					if(this.chatVisible) this.scrollToBottom();
				},
				immediate: true
			}
		}
	}
</script>

<style lang="scss" scoped>
	.feed_container
	{
		flex-grow: 1;
		height:100px;
		overflow:hidden;
	}
	.typing
	{
		padding: 5px;
		background: var(--background-color-user-foreign);
		border-radius: 5px 5px 5px 0;
		padding: 12px;
		display: inline-block;
		color: var(--white);
		font-size: 12px;
		margin: 5px;
		margin-bottom:20px;
	}
	.feed {
		background: var(--background-color-chat);
		width:600px;
		height:100%;
		overflow-x: hidden;
		overflow-y: scroll;
		ul {
			list-style-type: none;
			padding: 5px;
			li {
				&.message {
					margin: 10px 0;
					width: 100%;
					.avatar
					{
						display:block;
					}
					.misc
					{
						display:block;
					}
					.date
					{
						display:none;
					}
					.time
					{
						display:inline-block;
					}
					.image
					{
						width:50px;
						height:50px;
						border-radius:25px;
						display:none;
						margin-bottom:15px;
						background-size: cover;
					}
					.text {
						max-width: 400px;
						padding: 12px;
						display: inline-block;
						color: var(--white);
						font-size:12px;
					}
					&.received {
						text-align: left;
						.text {
							background: var(--background-color-user-foreign);
							border-radius: 0 5px 5px 0;
						}
						.time,.date {
							color: grey;
							font-size:10px;
							text-align:left;
						}
					}
					&.sent {
						text-align: right;
						.text {
							background: var(--theme_color);
							border-radius: 5px 0 0 5px;
						}
						.time,.date {
							color: grey;
							font-size:10px;
							text-align:right;
						}
					}
				}
			}
			li.sent+li.received,
			li.received+li.sent{
				.image,
				.misc .date
				{
					display:inline-block;
				}
			}
		}
	}
</style>
