require('../../../../../../resources/js/bootstrap');

"use strict";

let call_frame;
let wait_room_audio;
let current_user;
let current_operator;
let current_settings;
let dailyco_loaded = false;

/**
 * Array of all rooms with user data, dailyCO data and settings data
 * @type {*[]}
 */
let roomList = [];

let bot_user = {
    "forename": "Chat Bot",
    "surname" : "",
    "position" : "",
    "avatar_image" : "chatbot.jpg",
    "user_id" : 0,
    "role":"bot"
};

async function setupModule()
{
    if(typeof(window.DailyIframe) !== "function")
    {
        await new Promise((resolve, reject) => {
             var script = document.createElement('script');
             script.onload = () =>  resolve();
             script.src = "https://unpkg.com/@daily-co/daily-js";
             document.head.appendChild(script);
        })
    }

    const data = await (await fetch(`/room/${actualRoom}/module/Question/api?action=metadata`)).json();
    current_settings = data.settings //enabled focus message
    console.log(current_settings);

    //Set thankyou_message
    document.getElementById("thankyou_message").innerHTML = current_settings.thankyou_message;

    //Set disabled module message
    document.getElementById("disabled_message").innerHTML = current_settings.disabled_message;

    //Set disabled module instant message
    document.getElementById("instant_disabled_message").innerHTML = current_settings.instant_disabled_message;

    Echo.private(`room.${actualRoom}.module.Question.settings`)
   .listen('ModuleSettingsUpdate', async (event) => {
       //console.log("ModuleSettingsUpdate", event);

       //console.log(event.settings);
       if(event.settings.enabled == "0" && current_settings.enabled == "1")
       {
           //console.log("disable Module");
           disableModule();
       }
        else if(event.settings.enabled == "1" && current_settings.enabled == "0")
       {
           //console.log("enable Module");
           enableModule();
       }

        current_settings = event.settings

   });
   // No instant question request
   if(current_settings.instant_question_request == "0")
    {
           document.querySelector(".askQuestion").removeEventListener('click',handleAskQuestion)
           document.querySelector(".askQuestion").addEventListener('click',handleAskQuestion);
    }
    // Module enabled and instant question request
    if(current_settings.enabled == "1" && current_settings.instant_question_request == "1")
    {
        setupClient();
    }
    else if(current_settings.enabled == "0" && current_settings.disabled_welcome_message_show == "1") {
        document.querySelector(".question_disabled_welcome_container").style.display = "block";
    }
    if(current_settings.ask_again == "1")
    {
        document.querySelector(".askQuestionAgain").removeEventListener('click',handleAskQuestionAgain);
        document.querySelector(".askQuestionAgain").addEventListener('click',handleAskQuestionAgain);
    }
}

export async function setupClient() {
    //console.log("Client Setup");

    if(current_settings.message != "") pushMessageToChatHistory("incoming", bot_user, current_settings.welcome_message);

    requestRoom();

    call_frame = await window.DailyIframe.wrap(
        document.getElementById('callFrame'),
        {
            customLayout:true
        }
    );


    call_frame
        .on('loaded', dailycoLoaded)
        .on('started-camera', showEvent)
        .on('camera-error', showEvent)
        .on('joining-meeting', joinedMeeting)
        .on('joined-meeting', showEvent)
        .on('recording-started', showEvent)
        .on('recording-stats', showEvent)
        .on('recording-error', showEvent)
        .on('app-message', updateChat)
        .on('input-event', showEvent)
        .on('error', showEvent)
        .on('participant-joined', participantJoined)
        .on('participant-updated', updateParticipantList)
        .on('participant-left', participantLeft)
        .on('left-meeting', showEvent);

}

function dailycoLoaded()
{
    if(dailyco_loaded) return;
    setupButtons();
    setupWaitRoomAudio();
    dailyco_loaded = true;
}

function setupButtons()
{
    //console.log("setup");
    document.getElementById("button_chat_message").removeEventListener('click',handleSendChatMessage);
    document.getElementById("button_chat_message").addEventListener('click',handleSendChatMessage);

    document.getElementById('chat_form').removeEventListener('keypress',handleSendChatMessageByKey);
    document.getElementById('chat_form').addEventListener('keypress',handleSendChatMessageByKey);

    document.getElementById("dailyco_button_leave").removeEventListener('click',handleLeave);
    document.getElementById("dailyco_button_leave").addEventListener('click',handleLeave);

    document.getElementById("dailyco_button_toggle_chat").removeEventListener('click',handleToggleChat);
    document.getElementById("dailyco_button_toggle_chat").addEventListener('click',handleToggleChat);

    if(current_settings.hide_toggle_av_buttons == "1") return;

    document.getElementById("dailyco_button_toggle_audio").removeEventListener('click',handleToggleAudio);
    document.getElementById("dailyco_button_toggle_audio").addEventListener('click',handleToggleAudio);

    document.getElementById("dailyco_button_toggle_video").removeEventListener('click',handleToggleVideo);
    document.getElementById("dailyco_button_toggle_video").addEventListener('click',handleToggleVideo);
}

function handleLeave()
{
    deleteRoom(current_user);
    leaveCall();
}

function handleToggleChat()
{
    toggleChat()
}

function handleToggleAudio()
{
    call_frame.setLocalAudio(!call_frame.localAudio());
    document.getElementById('dailyco_button_toggle_audio').classList.toggle("mute");
}

function handleToggleVideo()
{
    call_frame.setLocalVideo(!call_frame.localVideo());
    document.getElementById('dailyco_button_toggle_video').classList.toggle("mute");
}

function handleAskQuestion()
{
  setupClient();
  document.querySelector(".question_disabled_welcome_container").style.display = "none";
  document.querySelector(".question_button_container").style.display = "none";
  document.querySelector(".question_thankyou_container").style.display = "none";
  document.querySelector(".question_container").style.display = "block";
}

function handleAskQuestionAgain()
{
    requestRoom();
    document.querySelector(".question_disabled_welcome_container").style.display = "none";
    document.querySelector(".question_button_container").style.display = "none";
    document.querySelector(".question_thankyou_container").style.display = "none";
    document.querySelector(".question_container").style.display = "block";
}

function handleSendChatMessage()
{
    if(document.getElementById("input_chat_message").value == "") return;
    outgoingChatMessage(document.getElementById("input_chat_message").value);
    document.getElementById("input_chat_message").value = "";
}

function handleSendChatMessageByKey(event)
{
    if (event.keyCode != 13) return;
    event.preventDefault();
    handleSendChatMessage();
}

async function deleteRoom(room)
{
    //console.log("deleteRoom");
//console.log(room);
    //Delete dailyco room and room in db
    const result = await ( await fetch(`/room/${actualRoom}/module/Question/api?action=deleteRoomByUser`, {
        method: 'DELETE',
        headers : {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(room)
    }));

    //console.log("DeleteRoom Result", result);
}

function setupWaitRoomAudio()
{
    if(current_settings.audio_play == 1 && current_settings.mp3 != "" && current_settings.instant_question_request != 1) wait_room_audio = new Audio(`/room/${actualRoom}/module/Question/public?action=mp3`);
    if(current_settings.audio_loop == 1) wait_room_audio.loop = true;
    wait_room_audio?.play();
}
/* Event listener callbacks */

async function requestRoom(){
    //console.log("RequestRoom");

    const response = (await fetch(`/room/${actualRoom}/module/Question/api?action=request`));
    const json = await response?.json();
    //console.log("Status is: "+json.room.settings.status);
    if(json.room.settings.status == 1) setKickstartPreview();
    else if(json.room.settings.status == 2) setKickstartLive();

    current_user = json;

    //console.log("subscribing to ", `room.${actualRoom}.module.Question.user.${current_user.user.id}.settingsChanged`, current_user);
    //Setup Websocket Event
    Echo.private(`room.${actualRoom}.module.Question.user.${current_user.user.id}.settingsChanged`)
        .listen('.Modules\\Question\\Events\\DailyCoRoomUserSettingsChanged', async (event) => {
            //console.log("************************************PUSHER**************************");
            //console.log(event);

            if(current_user.user.id === event.dailyCoRoom.user.id){
                if((current_user.room.settings.status == 1 || current_user.room.settings.status == 2) && event.dailyCoRoom.settings.status == 0)
                {
                    disableChat();
                }

                current_user.room = event.dailyCoRoom;

                switch(current_user.room.settings.status)
                {
                    // User waiting
                    case 0:
                        setBackgroundIdle();
                    break;

                    // User preview
                    case 1:
                        setBackgroundPreview();
                    break;

                    // User live
                    case 2:
                        setBackgroundLive();
                    break;
                }
            }
    });

    Echo.private(`room.${actualRoom}.module.Question.user.${current_user.user.id}.deleted`)
    .listen('.Modules\\Question\\Events\\DailyCoRoomUserDeleted', async (event) => {
        //console.log("DailyCoRoomUpdateDelete", event);
        leaveCall();

    });

    joinCall(json.room.dailyCo.name, json.user.id);

}

// Joins Daily call
// Passes the value in the 'room-url' input to call_frame.join
async function joinCall(roomURL,user_id) {
    await call_frame.join({
        url: current_settings.domain+roomURL,
        userName:"user_"+user_id,
        showLeaveButton: true,
        cssFile: `/room/${actualRoom}/module/Question/public?action=css&file=dailyco_module.css`,
        subscribeToTracksAutomatically:false,
    });
    await call_frame.setNetworkTopology({ topology: 'sfu' });

}

/* Event listener callbacks */

// Logs the Daily event to the console
function showEvent(e) {
    //console.log('call_frame event', e);
}

/**
* Displays the chat window on user click
* Changes style of "Chat" text from red, after new message, to white once clicked
*/
async function toggleChat() {
  document.querySelector('.chat').classList.toggle("hidden");
  document.getElementById('dailyco_button_toggle_chat').classList.toggle("mute");
  //document.getElementById("call_frame").height = document.getElementById("call_frame").contentWindow.document.body.scrollHeight + "px";
}

/**
* Updates a participant's local chat window display when the send button is clicked
* Uses sendAppMessage() to broadcast the message the participant typed to all participants
*/
async function outgoingChatMessage(message) {
// Local update
pushMessageToChatHistory("outgoing", current_user.user, message);
call_frame.sendAppMessage({ message: message }, '*');
}

/**
* Responds to an app-message event
* Appends a new "p" element to the "messages" div with the message the participant typed and their userName, if available
* Also updates the "Chat" text to red when a new message has been sent
*/
async function updateChat(e) {
    //console.log(current_operator);
    pushMessageToChatHistory("incoming",current_operator, e.data.message);
}

async function leaveCall()
{
    //console.log("leaveCall");
    document.querySelector(".ui-controller").classList.remove("show");
    call_frame?.leave();
    disableChat();
    //call_frame?.destroy();
    Echo.leaveChannel(`private-room.${actualRoom}.module.Question.user.${current_user.id}.settingsChanged`);
    Echo.leaveChannel(`private-room.${actualRoom}.module.Question.user.${current_user.id}.deleted`);
    wait_room_audio?.pause();
    document.querySelector(".question_disabled_welcome_container").style.display = "none";
    document.querySelector(".question_button_container").style.display = "none";
    document.querySelector(".question_container").style.display = "none";
    document.querySelector(".question_thankyou_container").style.display = "block";
    document.querySelector(".question_disabled_container").style.display = "none";
    setBackgroundIdle();
}

async function participantJoined(e)
{
      const [rank, userID] = e.participant.user_name.split("_");
      if(rank === "operator"){
          //console.log("Operator gejoined");
          const users = await ( await fetch(`/api/user/${userID}`)).json();
          current_operator = users[userID];
          //console.log(current_operator);
          wait_room_audio?.pause();
          document.querySelector(".question_container .loading_animation").style.display = "none";
          call_frame.updateParticipant(
              e.participant.user_id,
              { setSubscribedTracks: { audio: true, video: true, screenVideo: false } });
          enableChat();

      } else if(rank == "player")
      {
          //console.log("Joined");

         call_frame.updateParticipant(
                   e.participant.user_id,
                  { setSubscribedTracks: { audio: true, video: true, screenVideo: false } });
      }
   //console.log(e);
}

async function participantLeft(e)
{    // Get all the participants on the call
    const [rank, userID] = e.participant.user_name.split("_");
    if (rank === 'local' || rank === "player") return; // "You" is already added for the local user
    disableChat();
  //console.log(e);
}

function joinedMeeting(event)
{
    //console.log(event);
    document.querySelector(".ui-controller").classList.add("show");
}
/**
*  Updates participant list based on who is in a call and triggers the chat to send to whoever was not in the meeting
*  @param {Object} e is the event information Daily returns
*/
async function updateParticipantList(e) {

}

function setKickstartLive()
{
    setBackgroundLive();
    document.querySelector(".question_button_container").style.display = "none";
    document.querySelector(".question_container").style.display = "block";
}

function setKickstartPreview()
{
    setBackgroundPreview();
    document.querySelector(".question_button_container").style.display = "none";
    document.querySelector(".question_container").style.display = "block";
}

function pushMessageToChatHistory(type,user,message)
{
  var currentdate = new Date();
  var currenttime = currentdate.getHours() + ":" + currentdate.getMinutes() + ":" + currentdate.getSeconds();

  // Tabelle mit dem existierenden HTML tbody und der Zeile (row) aus dem template Element instantiieren
  var chat_message_template = document.querySelector('#chat_message');
  let float_position_class = ""

  if(type == "incoming") float_position_class = "left";
  else if(type == "outgoing") float_position_class = "right";

  var chat_message_template = document.querySelector('#chat_message').content.cloneNode(true);
  chat_message_template.querySelector("li").classList.add(float_position_class);
  chat_message_template.querySelector(".chat-img img").src = user.role=="bot"?"/img/avatars/"+user.avatar_image:"/image/user/"+user.id;
  chat_message_template.querySelector(".username").textContent = user.forename + " " + user.surname;
  chat_message_template.querySelector(".timestamp").textContent = currenttime;
  chat_message_template.querySelector(".message").textContent = message;

  var chat_history = document.getElementById("chat_history");
  chat_history.appendChild(chat_message_template);

  document.querySelector(".chat .panel-body").scrollTop = document.querySelector(".chat .panel-body").scrollHeight;
}

function enableChat()
{
  //Allow chat Message
  document.getElementById("input_chat_message").disabled = false;
  if(document.querySelector(".chat.inactive") != null) document.querySelector(".chat.inactive").classList.remove("inactive")
  document.querySelector(".chat .username").innerHTML = current_operator.forename+" "+current_operator.surname;
  //console.log(current_operator);
}

function disableChat()
{
  //Block chat Message
  document.getElementById("input_chat_message").disabled = true;
  if(document.querySelector(".chat.inactive") == null) document.querySelector(".chat").classList.add("inactive")
  document.querySelector(".chat .username").innerHTML = "";
}

function enableModule()
{
  document.querySelector(".askQuestion").removeEventListener('click',handleAskQuestion)
  document.querySelector(".askQuestion").addEventListener('click',handleAskQuestion);
  document.querySelector(".question_disabled_welcome_container").style.display = "none";
  document.querySelector(".question_button_container").style.display = "block";
  document.querySelector(".question_container").style.display = "none";
  document.querySelector(".question_thankyou_container").style.display = "none";
  document.querySelector(".question_disabled_container").style.display = "none";
}

function disableModule()
{
  if(current_user != null)
  {
    //console.log(current_user);
    //console.log("Leave WS Channels:")
    //console.log(`private-room.${actualRoom}.module.Question.user.${current_user.user.id}.settingsChaned`);
    //console.log(`private-room.${actualRoom}.module.Question.user.${current_user.user.id}.deleted`);
    Echo.leaveChannel(`private-room.${actualRoom}.module.Question.user.${current_user.user.id}.settingsChanged`);
    Echo.leaveChannel(`private-room.${actualRoom}.module.Question.user.${current_user.user.id}.deleted`);
  }
  call_frame?.leave();
  wait_room_audio?.pause();
  document.querySelector(".question_disabled_welcome_container").style.display = "none";
  document.querySelector(".question_button_container").style.display = "none";
  document.querySelector(".question_container").style.display = "none";
  document.querySelector(".question_thankyou_container").style.display = "none";
  document.querySelector(".question_disabled_container").style.display = "block";
  document.getElementById("chat_history").innerHTML = "";
  setBackgroundIdle();
}

function setBackgroundLive()
{
    if(current_settings.show_tally_colors != "1") return;
    if(document.querySelector(".question_container.preview") != null) document.querySelector(".question_container.preview").classList.remove("preview");
    if(document.querySelector(".question_container.live") == null) document.querySelector(".question_container").classList.add("live");
}

function setBackgroundPreview()
{
    if(current_settings.show_tally_colors != "1") return;
    if(document.querySelector(".question_container.live") != null) document.querySelector(".question_container.live").classList.remove("live");
    if(document.querySelector(".question_container.preview") == null) document.querySelector(".question_container").classList.add("preview");
}

function setBackgroundIdle()
{
    if(document.querySelector(".question_container.live") != null) document.querySelector(".question_container.live").classList.remove("live");
    if(document.querySelector(".question_container.preview") != null) document.querySelector(".question_container.preview").classList.remove("preview");

}

export default async(modul_id,actualRoom,settings) =>
{
    console.log('%c ❓ QuestionModule Loaded! ', 'background: #222; color: #bada55');
    setupModule();
    App.loadedModule(modul_id,actualRoom);
}
